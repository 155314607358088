<template>
    <div class="customer-detail">
        <ul v-if="hasItems">
          <hr/>
          <li class="error-line" v-for="(line, i) in items.lines" :key="i">Linha: {{ line.line }}, registro: {{ line.register }}, erro:  {{ line.error }}</li>
        </ul>
        <p v-else>Nenhuma falha de importação</p>
    </div>
</template>
<script>
export default {
  name: 'ErrorItem',
  props: ['items'],
  computed: {
    hasItems () {
      return this.items.lines && this.items.lines.length > 0
    }
  }
}
</script>

<style>
.customer-detail {
  background-color: #efefef;
}

.error-line {
  list-style: none;
}
</style>
