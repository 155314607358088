<template>
    <div class="customer-detail">
        <ul v-if="hasItems">
            <li class="imported-line" v-for="(line, i) in items.lines" :key="i">Linha: {{ line.line }}, registro: {{ line.register }}</li>
        </ul>
        <p v-else>Nenhum registro importado</p>
    </div>
</template>
<script>
export default {
  name: 'ImportedItem',
  props: ['items'],
  computed: {
    hasItems () {
      return this.items.lines && this.items.lines.length > 0
    }
  }
}
</script>

<style>
.customer-detail {
  background-color: #efefef;
}

.imported-line {
  list-style: none;
}
</style>
