<template>
    <div class="customer-detail p-2 m-2">
      <p v-if="item.indicacao"><strong>Indicação:</strong> {{ item.indicacao }}</p>
      <p class="pb-0 mb-0"><strong>Contatos</strong></p>
      <p v-for="(contato, i) in item.contatos" :key="i" class="pb-0 mb-0">{{ contato.replace('|', '').trim() }}</p>

      <p class="pb-0 mb-0 pt-2 mt-2">{{ item.embarcadosEsteAno }} <strong>embarcados</strong> este ano ({{ item.embarcadosAnoAnterior }} no ano anterior)</p>

      <p v-if="item.diasUltimaVisita" class="pb-0 mb-0">{{ item.diasUltimaVisita }} dias da última <strong>visita</strong> ({{ item.dataUltimaVisita }})</p>
      <p v-else class="pb-0 mb-0">Nenhuma <strong>visita</strong> realizada</p>

      <p v-if="item.diasUltimoContato" class="pb-0 mb-0">{{ item.diasUltimoContato }} dias do último <strong>contato</strong> ({{ item.dataUltimoContato }})</p>
      <p v-else class="pb-0 mb-0">Nenhum <strong>contato</strong> realizado</p>

      <p v-if="item.diasUltimoProcesso" class="pb-0 mb-0">{{ item.diasUltimoProcesso }} dias do último <strong>processo</strong> ({{ item.dataUltimoProcesso }})</p>
      <p v-else class="pb-0 mb-0">Nenhum <strong>processo</strong> realizado</p>

      <p>{{ item.cotacoesAprovadas }} <strong>cotações</strong> aprovadas de {{ item.cotacoesRealizadas }} realizadas</p>

      <p class="pb-0 mb-0"><strong>Último Contato</strong></p>
      <p class="pb-0 mb-0">{{ item.ultimoContato.texto }}</p>
      <p class="pb-0 mb-0"><em><small>({{ item.ultimoContato.responsavel }})</small></em></p>
    </div>
</template>
<script>
export default {
  name: 'CustomerDetail',
  props: ['item']
}
</script>

<style>
.customer-detail {
  background-color: #efefef;
}
</style>
