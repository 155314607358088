<template>
  <div>
    <imported-item v-if="item.success" :items="item" />
    <error-item v-if="!item.success" :items="item" />
  </div>
</template>

<script>
import ImportedItem from '@/views/Basic/Tariff/Import/ImportedItem.vue'
import ErrorItem from '@/views/Basic/Tariff/Import/ErrorItem.vue'

export default {
  name: 'ResultLine',
  props: ['item'],
  components: {
    ImportedItem,
    ErrorItem
  },
  computed: {
    hasItems () {
      return this.item.lines && this.item.lines.length > 0
    }
  }
}
</script>

<style>
.customer-detail {
  background-color: #efefef;
}
</style>
