<template>
    <div :class="classOut">
        <div class="card card-fullheight">
            <div class="card-body">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'CardBox',
  props: {
    classOut: {
      type: String,
      required: false
    }
  }
}

</script>
