<template>
  <div>
    <div class="pb-2 mb-2 collapsive-header">
      <a data-toggle="collapse" :href="'#collapse'+listName+index" class="collapsiveLink collapsed">
        <div class="row">
          <div class="col-11">
            <p class="pb-0 mb-0" v-html="title"></p>
            <p class="pb-0 mb-0" v-html="subtitle"></p>
          </div>
          <div class="col-1 float-right">
            <div class="row h-100 d-flex justify-content-center align-items-center">
              <i class="fas fa-caret-down"></i>
            </div>
          </div>
        </div>
      </a>
    </div>
    <div class="collapse pb-2 mb-2" :id="'collapse'+listName+index">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CollapsiveItem',
  props: [
    'item',
    'index',
    'title',
    'subtitle',
    'listName',
    'length'
  ]
}
</script>

<style>
.collapsive-header .fas {
  transition: .3s transform ease-in-out;
}

.collapsive-header .collapsed .fas {
  transform: rotate(-90deg);
}
</style>
