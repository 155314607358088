<template>
    <div>
      <template v-if="Object.keys(data).length == 0">
        <p><em>{{ emptyMessage }}</em></p>
      </template>
      <template v-else>
        <CollapsiveItem
          v-for="(item, i) in data"
            :title="item.title"
            :subtitle="item.subtitle"
            :index="item.index"
            :key="i"
            :listName="name"
            :length="Object.keys(data).length"
            :open="item.open"
            @toogle-open="toogleOpen">
            <component :is="componentDetail" :item="item" />
        </CollapsiveItem>
      </template>
    </div>
</template>

<script>
import CollapsiveItem from '@/components/Collapsive/Item'
// Deatils
import CustomerDetail from '@/views/Report/Seller/CustomerDetail.vue'
import ResultLine from '@/views/Basic/Tariff/Import/ResultLine.vue'
// import CustomDetail from '@/views/.../CustomDetail.vue'

export default {
  name: 'CollapsiveList',
  props: {
    componentDetail: {
      type: String
    },
    data: {
      type: Array,
      defalt: []
    },
    name: {
      type: String,
      default: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    },
    emptyMessage: {
      type: String,
      default: 'Não há dados'
    }
  },
  components: {
    CollapsiveItem,
    CustomerDetail,
    ResultLine
  },
  mounted () {
  },
  methods: {
    toogleOpen (index) {
      this.dados = this.dados.map((item, i) => {
        if (index === i) {
          item.open = !item.open
        }
        return item
      })
    }
  }
}
</script>

<style>
.collapsiveLink {
  color:inherit;
  text-decoration: none;
}

.collapsiveLink :hover {
  color: #767541;
  text-decoration: none;
}

.collapsive-item {
  display: block;
  width: 100%;
}

.collapsive-item .title {
  cursor: pointer;
}
</style>
