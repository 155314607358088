<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center">

      <!-- Seleção de filtros -->
      <div v-if="showEmployeeFilter" class="col-12">
        <filter-side-bar>
          <template slot="ContentSideBar">
            <form id="processes-list-filter" ref='processesListFilter' v-on:submit.prevent="applyFilter()">
              <h5 class="mb-0">Filtrar por:</h5>

              <div v-if="showEmployeeFilter" class="mt-5">
                  <div class="md-form mb-0 ">
                    <div class="md-form mb-0">
                      <select class="md-form-control" :value="filters.filEmployee" id="employee" name="employee" ref="employee">
                        <option v-for="(item, index) in sellerList" v-bind:key="index" :value="item.id_employee"  :selected="filters.filEmployee === item.id_employee" >{{ item.name }}</option>
                      </select>
                      <label :class="{'active': true}">{{ $t('form.report.seller') }}</label>
                    </div>
                  </div>
              </div>

              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}
                      </button>
                    </div>
                    <div class="col-6">
                      <button type="button" v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">{{
                        $t('filter.reset') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>

      <!-- Exibição dos filtros ativos -->
      <div class="col-12 mb-3">
        <filter-container :activeFilterList="activeFilterList" />
      </div>

      <!--  Planejamento Semanal - semana N     -->
      <div class="col-12">
        <h5>Planejamento Semanal - {{ fullMonth }} ({{ day }})</h5>
        <p class="pt-0 mt-0 mf-2 pl-2"><small>({{ formatDateTime(date) }})</small></p>
        <CardBox>
          <h6 class="pt-3 pb-2"><strong>{{ isset(employee) && isset(employee.nickname) ? 'Oi ' + employee.nickname : 'Oi' }},</strong></h6>
          <SimpleText :text="helloText" :customClasses="classesSimpleText" />
        </CardBox>

          <!--  Estratégia 1 - Clientes Esquecidos     -->
        <CardBox>
          <h6 class="pt-3 pb0 mb-0"><strong>Estratégia 01 - Clientes Esquecidos</strong></h6>
          <p class="text-small mt0 pt0">Já embarcaram um dia mas estão há mais de 90 dias sem contato - TOP10</p>
          <hr class="mt-2">
          <CollapsiveList
            componentDetail="CustomerDetail"
            :data="forgotten_customers"
            name="forgotten_customers"
            emptyMessage="Nenhum cliente esquecido"
          />
        </CardBox>

          <!--  Estratégia 02 - Clientes em Desistência     -->
        <CardBox>
            <h6 class="pt-3 pb0 mb-0"><strong>Estratégia 02 - Clientes em Desistência</strong></h6>
            <p class="text-small mt0 pt0">Já embarcaram um dia mas estão há mais de 90 dias sem embarque - TOP10</p>
          <hr class="mt-2">
          <CollapsiveList
            componentDetail="CustomerDetail"
            :data="customers_in_desistance"
            name="customers_in_desistance"
            emptyMessage="Nenhum cliente em desistência"
          />
        </CardBox>

          <!--  Estratégia 03 - Clientes Grandes com Índice de Aprovação menos de 50%     -->
        <CardBox>
            <h6 class="pt-3 pb0 mb-0"><strong>Estratégia 03 - Clientes Grandes com Índice de Aprovação menos de 50%</strong></h6>
            <p class="text-small mt0 pt0">Possuem grande volume (4-6) mas não aprovam mais do que 50% das cotações - TOP10</p>
          <hr class="mt-2">
          <CollapsiveList
            componentDetail="CustomerDetail"
            :data="potential_customers"
            name="potential_customers"
            emptyMessage="Nenhum cliente grande com índice de aprovação menor de 50%"
          />
        </CardBox>

          <!--  Estratégia 04 - Prospects Esquecidos     -->
        <CardBox>
            <h6 class="pt-3 pb0"><strong>Estratégia 04 - Prospects Esquecidos</strong></h6>
            <p class="text-small mt0 pt0">Receberam uma proposta há mais de 120 dias ou não receberam nenhuma - TOP10</p>
          <hr class="mt-2">
          <CollapsiveList
            componentDetail="CustomerDetail"
            :data="forgotten_prospects"
            name="forgotten_prospects"
            emptyMessage="Nenhum prospects esquecido"
          />
        </CardBox>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Components
import FilterSideBar from '@/components/FilterSideBar.vue'
import FilterContainer from '@/components/FilterContainer'
import SimpleText from '@/components/Basic/SimpleText'
import CardBox from '@/components/Basic/CardBox'
import CollapsiveList from '@/components/Collapsive/List'

// Loading
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// Services
import ReportService from '@/services/ReportService'
import EmployeeService from '@/services/EmployeeService'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

export default {
  name: 'SellerInsight',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('report.report_comercial.insight') + ' - %s'
    }
  },
  data () {
    return {
      employee: {},
      sellerList: [],
      helloText: [
        'Quero te ajudar a planejar a sua semana!',
        'Vamos fazer assim, eu te mostro algumas estratégias para orientar a tua semana para te ajudar a potencializar os teus resultados, pode ser?',
        'Faz assim, entra em contato com o Diretor Comercial, combina com ele qual estratégia faz mais sentido para você e mãos à obra. Ah! Não esqueça de ir atualizando as informações comerciais e cadastrais do cliente no Head Cargo para que o próximo relatório venha atualizado, OK?',
        'Fiz alguns filtros na nossa base de dados para te dar alguns <em>insights</em> sobre a sua carteira de clientes e dividi em quatro estratégias:'
      ],
      classesSimpleText: {
        div: 'pt-2 pb-2',
        p: 'p-0 m-0'
      },
      fullMonth: null,
      day: null,
      date: null,
      seller: {
        nome: null
      },
      forgotten_customers: [],
      customers_in_desistance: [],
      potential_customers: [],
      forgotten_prospects: [],
      sem_registros: [],
      isLoading: true,
      fullPage: true,
      activeFilterList: {
        employee: null
      },
      firstFilter: true,
      filter: {},
      httpOptions: {
        headers: {
          'Authorization': 'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        }
      },
      css: {
        table: {
          tableClass: 'table table-hover align-middle table-clickable',
          loadingClass: 'loading',
          ascendingIcon: 'ti-angle-double-up',
          descendingIcon: 'ti-angle-double-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger'
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination text-center',
          activeClass: 'page-item active',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: ''
          }
        }
      }
    }
  },
  components: {
    FilterSideBar,
    FilterContainer,
    CardBox,
    SimpleText,
    CollapsiveList,
    Loading
  },
  beforeMount () {
    /**
     * Returns the week number for this date.  dowOffset is the day of week the week
     * "starts" on for your locale - it can be from 0 to 6. If dowOffset is 1 (Monday),
     * the week returned is the ISO 8601 week number.
     * @param int dowOffset
     * @return int
     */

    this.buildFilters()
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', true)
    this.$store.dispatch('setShowSearch', false)
  },
  methods: {
    getTitle () {
      let title = 'Relatório semanal'
      return title
    },
    hideQuickSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    onLoading () {
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
      this.$jQuery('table.table').reflowTable()
      if (this.$route.query.process) {
        if (this.$refs.vuetable.$data.tableData) {
          if (this.$refs.vuetable.$data.tableData.length > 0) {
            this.onRowClicked({ data: this.$refs.vuetable.$data.tableData[0] })
          }
        }
      }
    },
    handleLoadError (response) {
    },
    // Filters
    getActiveFilterList () {
      let activeFilterList = {
        period: null,
        employee: null,
        year: null
      }

      return activeFilterList
    },
    buildFilters () {
      if ((!this.filters.filEmployee || this.filters.filEmployee.length <= 0)) {
        // Se usuário for um vendedor filtro pelo seu usuário ao entrar no sistema
        if (this.isSeller & !this.isDirector | this.isDirector & this.firstFilter) {
          this.filters.filEmployee = this.$store.getters.userInfo.active_employee.id_employee
          this.firstFilter = false
        } else {
          // Senão filtro pelo primeiro vendedor da lista
          this.filters.filEmployee = this.sellerList[0].id_employee
        }
      }

      this.filter.employee = this.filters.filEmployee
      this.$store.dispatch('setAppliedFilter', this.filters)
    },
    applyFilter () {
      this.filters.filEmployee = this.$refs.employee ? parseInt(this.$refs.employee.value) : null
      this.buildFilters()
      this.$store.dispatch('setAppliedFilter', this.filters)
      this.hideQuickSidebar()

      /* Todo: Reload Charts */
      this.getReportSellerData()
    },
    resetFilter (event) {
      this.hideQuickSidebar()

      if ((!this.filters.filEmployee || this.filters.filEmployee.length <= 0)) {
        // Se usuário for um vendedor filtro pelo seu usuário ao entrar no sistema
        if (this.isSeller) {
          this.filters.filEmployee = this.$store.getters.userInfo.active_employee.id_employee
        } else {
          // Senão filtro pelo primeiro vendedor da lista
          this.filters.filEmployee = this.sellerList[0].id_employee
        }
      }

      this.$store.dispatch('setAppliedFilter', this.filters)
      this.buildFilters()
      this.getReportSellerData()
    }, // End Filters
    // Getters
    getReportSellerData () {
      let me = this
      me.isLoading = true

      if (this.$store.getters.userInfo) {
        EmployeeService.getSellers([]).then(res => {
          me.sellerList = res.data.data
          me.buildFilters()

          ReportService.getReportSellerInsight(this.filter.employee, this.filter).then(res => {
            me.employee = res.data.data.employee
            me.fullMonth = res.data.data.fullMonth
            me.day = res.data.data.day
            me.date = res.data.data.date
            me.forgotten_customers = this.formatRegister(res.data.data.forgotten_customers, 'forgotten_customers')
            me.customers_in_desistance = this.formatRegister(res.data.data.customers_in_desistance, 'customers_in_desistance')
            me.potential_customers = this.formatRegister(res.data.data.potential_customers, 'potential_customers')
            me.forgotten_prospects = this.formatRegister(res.data.data.forgotten_prospects, 'forgotten_prospects')

            this.isLoading = false
          }).finally(function () {
            me.isLoading = false
          })
        })
      }
    }, // End Getters
    // Formatters
    formatRegister (list, type) {
      return Object.keys(list).map((key, i) => {
        let modificadorDiasNoEmail = 90
        let fatorDiasNoEmail = 0

        switch (type) { // alguém me explica esse cálculo...
          case 'forgotten_customers':
            fatorDiasNoEmail = list[key].Dias_Ultimo_Contato
            break
          case 'customers_in_desistance':
            fatorDiasNoEmail = list[key].Dias_Ultimo_Embarque
            break
          case 'potential_customers':
            fatorDiasNoEmail = list[key].Dias_Ultima_Proposta
            modificadorDiasNoEmail = 0
            break
          case 'forgotten_prospects':
            fatorDiasNoEmail = list[key].Dias_Ultimo_Contato
            modificadorDiasNoEmail = 0
            break
          default:
            modificadorDiasNoEmail = 0
            break
        }

        let preSubtitle = '<strong>Classificação:</strong> ' + list[key].Qualificacao +
            ' | <strong>Tipo:</strong> ' + list[key].Nome_tipo_cliente +
            (list[key].Volume ? ' | <strong>Volume​:</strong> ' + list[key].Volume : '')
        preSubtitle += modificadorDiasNoEmail ? ' | <strong>Dias no email: ' + (fatorDiasNoEmail - modificadorDiasNoEmail) + '</strong>' : ''

        let city = list[key].Municipio ? list[key].Municipio.toUpperCase() + '/' : ''

        let contacts = list[key].Contatos ? list[key].Contatos.split('||') : []

        return {
          indicacao: list[key].Indicacao,
          title: '<strong>' + list[key].IdPessoa + ' - ' + list[key].Empresa + '</strong>&nbsp;<small> - ' + city + (list[key].UF ? list[key].UF : '') + '</small>',
          subtitle: preSubtitle,
          nome: list[key].IdPessoa + ' - ' + list[key].Empresa,
          cidade: city + ' - ' + list[key].UF,
          classificacao: list[key].Qualificacao,
          tipo: list[key].Nome_tipo_cliente,
          volume: list[key].Volume,
          contatos: contacts,
          embarcadosEsteAno: list[key].Embarcados_Ano_Vigente,
          embarcadosAnoAnterior: list[key].Embarcados_Ano_Anterior,
          diasUltimaVisita: list[key].Dias_Ultima_Visita,
          dataUltimaVisita: this.formatDateTime(list[key].Data_Ultima_Visita),
          diasUltimoContato: list[key].Dias_Ultimo_Contato,
          dataUltimoContato: this.formatDateTime(list[key].Data_Ultimo_Contato),
          diasUltimoProcesso: list[key].Dias_Ultimo_Processo,
          dataUltimoProcesso: this.formatDateTime(list[key].Data_Ultimo_Processo),
          cotacoesAprovadas: list[key].Qtd_Cotacoes_Aprovadas,
          cotacoesRealizadas: list[key].Qtd_Cotacoes,
          ultimoContato: {
            texto: list[key].Descricao_Ult_Acm,
            responsavel: list[key].Responsavel_Acom
          },
          index: i,
          open: false
        }
      })
    },
    formatDateTime (value, locale, emptyValue) {
      emptyValue = emptyValue || null

      if (!value) {
        return emptyValue
      }

      locale = locale || 'pt-BR'

      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }

      if (!value) {
        return null
      }

      let date = new Date(value)

      if (locale === 'pt-BR') {
        options.timeZone = 'America/Sao_Paulo'

        return date.toLocaleString('pt-BR', options)
      } else {
        options.timeZone = 'America/New_York'

        return date.toLocaleString('en-US', options)
      }
    },
    numberFormat (number, decimals, decPoint, thousandsSep) {
      return util.numberFormat(number, decimals, decPoint, thousandsSep)
    },
    showNumber (number, thousandsSep, defaultValue) {
      defaultValue = (defaultValue !== undefined) ? defaultValue : 0
      let decimals = 0
      let decPoint = ','

      if (thousandsSep !== '') {
        thousandsSep = thousandsSep || '.'
      }

      // eslint-disable-next-line
      if (number != parseInt(number)) {
        decimals = 2
      }

      if (number) {
        return this.numberFormat(number, decimals, decPoint, thousandsSep)
      }

      return defaultValue
    },
    showInteger (number, thousandsSep, defaultValue) {
      defaultValue = (defaultValue !== undefined) ? defaultValue : 0
      let decimals = 0
      let decPoint = ','
      thousandsSep = thousandsSep || '.'

      if (number) {
        return this.numberFormat(number, decimals, decPoint, thousandsSep)
      }

      return defaultValue
    },
    isset (variable) {
      return variable !== 'undefined' && variable != null && variable !== ''
    }
  },
  computed: {
    isDirector () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_DIRECTOR') !== -1) {
        return true
      }
      return false
    },
    isFinancial () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_FINANTIAL') !== -1) {
        return true
      }
      return false
    },
    isSuperAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    },
    isSeller () {
      let userInfo = this.$store.getters.userInfo || null

      if (userInfo && userInfo.roles.indexOf('ROLE_EMPLOYEE') !== -1) {
        if (userInfo.active_employee) {
          if (userInfo.active_employee.is_seller) {
            return true
          }
        }
      }

      return false
    },
    showEmployeeFilter () {
      if (this.user) {
        let showEmployee = false
        if (this.user.roles.filter(role => role === 'ROLE_DIRECTOR').length > 0) {
          showEmployee = true
        } else if (this.user.roles.filter(role => role === 'ROLE_SUPER_ADMIN').length > 0) {
          showEmployee = true
        } else if (this.user.roles.filter(role => role === 'ROLE_FINANTIAL').length > 0) {
          showEmployee = true
        } else if (this.user.roles.filter(role => role === 'ROLE_COMERCIAL_ADMIN').length > 0) {
          showEmployee = true
        } else if (this.user.roles.filter(role => role === 'ROLE_SALES_COORDINATOR').length > 0) {
          showEmployee = true
        }

        return showEmployee
      } else {
        return false
      }
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    filters () {
      if (this.$Cookie.get('_userStoredFilters_')) {
        return JSON.parse(this.$Cookie.get('_userStoredFilters_'))
      } else {
        return null
      }
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    }
  },
  watch: {
    user: function () {
      this.getReportSellerData()
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style>
  .card-body {
    padding: 0.5rem 1.8rem;
  }

  @media (max-width: 576px) {

    .card-body {
      padding: 0.8rem 0.2rem;
    }

    .gray-box {
      padding: 0.8rem 0.2rem;
    }
  }

  .dark-gray-box {

    background-color: #e3e2e1;

    border-radius: 0 0 38px 38px;
    margin-bottom: 10px;
    padding: 25px;
    padding-left: 75px;
    padding-right: 75px;
    padding-bottom: 1px;
  }

  .dark-gray-box .line {
    margin-top: 10px;
    text-align: center;
    margin-bottom: 27px;
  }

  .label {
    padding-left: 47px;
    padding-top: 7px;
    padding-bottom: 7px;
    background-position: center left;
    background-repeat: no-repeat;
    margin-left: 10px;
    font-size: 11px;
    font-weight: bold;

  }

  .label.air {
    background-image: url("/static/images/report/air_icon.png");
  }

  .label.ship {
    background-image: url("/static/images/report/ship_icon.png");
  }

  .label.lcl {
    background-image: url("/static/images/report/lcl_icon.png");
  }

  .label.fcl {
    background-image: url("/static/images/report/fcl_icon.png");
  }

  .label.insurance {
    background-image: url("/static/images/report/insurance_icon.png");
  }

  .label.green {
    background-image: url("/static/images/report/green_icon.png");
    padding-left: 20px;
  }

  .label.yellow {
    background-image: url("/static/images/report/yellow_icon.png");
    padding-left: 20px;
  }

  .label.red {
    background-image: url("/static/images/report/red_icon.png");
    padding-left: 20px;
  }

  .card-legend, .card-title {
    color: #84754E;
    text-align: center;
    font-size: 18px;
  }

  .card-title, .card-title b {
    margin-top: 45px;
    margin-bottom: 0;
    font-size: 19px;

  }

  .summary-box .message{
    font-weight: 200;
    font-size: 14px;
    font-family: pluto_sanscond_light;
  }

  .gray-box {
    background-color: #F3F1ED;
    border-radius: 38px 38px 0 0;
    margin-top: 82px;
  }

  /* Sellect Section */
  .row.seller-box {
    margin-bottom: -100px;
  }

  .seller {
    text-align: center;
    top: -100px;

  }

  .seller img {
    border: 10px solid #F3F1ED;
    border-radius: 148px;
    width: 148px;
    position: relative;
    overflow: hidden;
  }

  .seller h2 {
    margin: 12px 0;
    font-size: 25px;
  }

  .summary-box {
    margin-top: 39px;
  }

  /* Message box */
  .message-box {
    margin: 0 2.8rem;
    color: #84734C;
    font-size: 14px;
  }

  @media (max-width: 576px) {
    .message-box {
      margin: 0 0.8rem;
    }
  }

  /* Details */
  .detail-box .indicator-box {
    background-color: #fefefe;
    border-radius: 0 0 40px 40px;
    padding: 50px 60px;
  }

  .detail-box.first .indicator-box {

    border-radius: 40px 40px 0 0;
    padding: 0 60px;
    padding-top: 50px;
  }

  .detail-box.middle .indicator-box {

    border-radius: 0 0 0 0;
    padding: 0 60px;
    padding-bottom: 65px;
  }

  .detail-box.first {
    margin-top: 70px;

  }
  .detail-box.last .indicator-box, .detail-box.simple .indicator-box {
    padding-bottom: 45px;

  }

  .detail-box.simple {
    margin-top: 70px;
  }

  .detail-box.simple .indicator-box {
    border-radius: 40px 40px 40px 40px;
  }

  /* Activities */

  .activities h3 {
    margin-top: 0;
    color: #84754E;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
  }

  .activities h3.message {
    margin-top: 45px;
    font-size: 19px;
    font-weight: bold;
  }

  .activities .card-title {
    text-align: center;
    font-size: 13px !important;
    font-weight: bold;
    color: #84754E;
  }

  .activities .icon {

    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 55px;
  }

  .activities .icon.price {
    background-image: url("/static/images/report/price_icon.png");
  }

  .activities .icon.visits {
    background-image: url("/static/images/report/visits_icon.png");
  }

  .activities .icon.email {
    background-image: url("/static/images/report/email_icon.png");
  }

  .activities .icon.presentation {
    background-image: url("/static/images/report/presentations_icon.png");
  }

  .activities .icon.callPhones {
    background-image: url("/static/images/report/cellPhone_icon.png");
  }

  /* right operation / modal label */
  .detail-box .col-md-2 {
    padding: 0;
  }

  .detail-box .col-md-2 p {
    top: 113px;
    font-size: 26px;
    font-weight: 200;
    color: #84754E;
  }

  .detail-box .col-md-2 p {
    top: 197px;
    left: 0;
    padding-left: 58px;
    padding-top: 18px;
    padding-bottom: 18px;
    font-size: 23px;
    font-weight: 200;
    position: relative;
    background-image: url("/static/images/report/carret.png");
    background-position: -9px center;
    background-repeat: no-repeat;

  }

  .chart-label .medium {
    font-size: 18px
  }

  .card-legend .small {
    font-size: 11px !important;
  }

  .card-title.info {
    margin-top: 59px;
  }

  .card-title.center.info {
    margin-top: 134px;
  }

  .card-title.info, .card-title.info b, .card-legend.info {
    font-size: 15px;
  }
</style>
