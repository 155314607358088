<template>
  <div :class="customClasses.div">
      <p v-for="line in text" :key="line" :class="customClasses.p" v-html="line"></p>
  </div>
</template>

<script>
export default {
  name: 'SimpleText',
  props: {
    text: Array,
    customClasses: {
      type: Object,
      default () {
        return {
          div: '',
          p: ''
        }
      }
    }
  }
}
</script>
